import { Stack, HStack, Button, Box, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { FiCircle, FiStopCircle } from "react-icons/fi";
import { useVideo } from "../../../hooks/useVideo";
import { detectAndDrawResults, loadModels } from "../lib/api";

export default function FaceDetector() {
	const [recording, setRecording] = useState(false);
	const { error, stream, start, stop } = useVideo();
	const [loading, setLoading] = useState(false);
	const videoRef = useRef(null);
	const canvasRef = useRef(null);

	useEffect(() => {
		if (stream) {
			let myVideo = videoRef.current as any;
			myVideo.srcObject = stream;
		}
	}, [stream]);

	useEffect(() => {
		let video = videoRef.current as any;
		if (video) {
			video.addEventListener("loadedmetadata", () => {
				detectAndDrawResults(canvasRef.current!, video);
				setLoading(false);
			});
		}
	}, [videoRef]);

	useEffect(() => {
		if (error) {
			setRecording(false);
		}
	}, [error]);

	const startDetection = () => {
		setLoading(true);
		loadModels().then(() => {
			setRecording(true);
			start({});
		});
	};

	const stopDetection = () => {
		setRecording(false);
		stop();
	};

	return (
		<Stack>
			<HStack>
				<Button isLoading={loading} disabled={error} onClick={recording ? stopDetection : startDetection} variant="solid" colorScheme="red" alignSelf="start" leftIcon={!recording ? <FiCircle /> : <FiStopCircle />}>
					{!recording ? "Start Detection" : "Stop"}
				</Button>
			</HStack>
			{error && <Text color="red">this feature requires access to your camera, Please allow it and refresh your browser </Text>}
			<Box position="relative">
				<video ref={videoRef} autoPlay={true} muted />
				<canvas style={{ position: "absolute", top: 0, left: 0 }} ref={canvasRef}></canvas>
			</Box>
		</Stack>
	);
}

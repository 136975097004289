import { Button, Icon, InputGroup } from "@chakra-ui/react";
import React, { useRef } from "react";
import { FiFile } from "react-icons/fi";

type Props = {
	onChange: (value: any) => void;
};
export default function FileInput({ onChange }: Props) {
	const inputRef = useRef<HTMLInputElement | null>(null);

	const handleClick = () => inputRef.current?.click();

	return (
		<InputGroup onClick={handleClick}>
			<input
				onChange={onChange}
				type="file"
				multiple={false}
				hidden
				accept="image/*"
				ref={e => {
					inputRef.current = e;
				}}
			/>
			<Button variant="outline" leftIcon={<Icon as={FiFile} />}>
				Upload
			</Button>
		</InputGroup>
	);
}

import { useState } from "react";

export const useVideo = () => {
	const [video, setVideo] = useState<any>(null);
	const [stream, setStream] = useState<any>(null);
	const [error, setError] = useState<boolean>(false);

	const start = (options: any) => {
		navigator.mediaDevices
			.getUserMedia({ video: options })
			.then(stream => {
				let newVideo = document.createElement("video");
				if (newVideo) {
					newVideo.srcObject = stream;
					setStream(stream);
					setVideo(newVideo);
					setError(false);
				}
			})
			.catch(err => {
				setError(true);
				console.error("error:", err);
			});
	};

	const stop = () => {
		video.pause();
		video.srcObject.getTracks().forEach((track: any) => {
			track.stop();
		});
		setStream(null);
		setVideo(null);
	};

	return { error, video, stream, start, stop };
};

import { Box, BoxProps, CloseButton, Drawer, DrawerContent, Flex, FlexProps, Icon, IconButton, Image, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { ReactText } from "react";
import { IconType } from "react-icons";
import { FaRegObjectUngroup } from "react-icons/fa";
import { FiHome, FiMenu, FiSmile, FiVideo } from "react-icons/fi";
import { NavLink, useLocation } from "react-router-dom";

import avatar from "../../assets/avatar.png";
import { ColorModeSwitcher } from "./ColorModeSwitcher";

interface LinkItemProps {
	name: string;
	icon: IconType;
	link: string;
}
const LinkItems: Array<LinkItemProps> = [
	{ name: "Home", icon: FiHome, link: "/" },
	{ name: "Terminal Video", icon: FiVideo, link: "/term" },
	{ name: "Face Detector", icon: FiSmile, link: "/face" },
	{ name: "Object Detector", icon: FaRegObjectUngroup, link: "/object" }
];

export default function Sidebar({ children }: { children: ReactNode }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
			<SidebarContent onClose={() => onClose} display={{ base: "none", md: "block" }} />
			<Drawer autoFocus={false} isOpen={isOpen} placement="left" onClose={onClose} returnFocusOnClose={false} onOverlayClick={onClose} size="full">
				<DrawerContent>
					<SidebarContent onClose={onClose} />
				</DrawerContent>
			</Drawer>
			{/* mobilenav */}
			<MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
			<Box ml={{ base: 0, md: 60 }} p="4">
				{children}
			</Box>
		</Box>
	);
}

interface SidebarProps extends BoxProps {
	onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
	let location = useLocation();
	return (
		<Box bg={useColorModeValue("white", "gray.900")} borderRight="1px" borderRightColor={useColorModeValue("gray.200", "gray.700")} w={{ base: "full", md: 60 }} pos="fixed" h="full" {...rest}>
			<Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
				<Image h="100%" src={avatar} />
				<ColorModeSwitcher justifySelf="flex-end" />
				<CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
			</Flex>
			{LinkItems.map(link => (
				<NavItem active={location.pathname === link.link} key={link.name} icon={link.icon} link={link.link}>
					{link.name}
				</NavItem>
			))}
		</Box>
	);
};

interface NavItemProps extends FlexProps {
	active: boolean;
	link: string;
	icon: IconType;
	children: ReactText;
}
const NavItem = ({ active, link, icon, children, ...rest }: NavItemProps) => {
	return (
		<NavLink to={link} style={{ textDecoration: "none" }}>
			<Flex
				align="center"
				p="4"
				mx="4"
				borderRadius="lg"
				role="group"
				cursor="pointer"
				bg={active ? "cyan.400" : ""}
				color={active ? "white" : ""}
				_hover={{
					bg: "cyan.400",
					color: "white"
				}}
				{...rest}
			>
				{icon && (
					<Icon
						mr="4"
						fontSize="16"
						_groupHover={{
							color: "white"
						}}
						as={icon}
					/>
				)}
				{children}
			</Flex>
		</NavLink>
	);
};

interface MobileProps extends FlexProps {
	onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
	return (
		<Flex
			ml={{ base: 0, md: 60 }}
			px={{ base: 4, md: 24 }}
			height="20"
			alignItems="center"
			bg={useColorModeValue("white", "gray.900")}
			borderBottomWidth="1px"
			borderBottomColor={useColorModeValue("gray.200", "gray.700")}
			justifyContent="flex-start"
			{...rest}
		>
			<IconButton variant="outline" onClick={onOpen} aria-label="open menu" icon={<FiMenu />} />
			<Image h="100%" src={avatar} />
			<ColorModeSwitcher justifySelf="flex-end" />
		</Flex>
	);
};

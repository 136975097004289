import * as faceapi from "face-api.js";

export const loadModels = () => {
	return Promise.all([
		faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
		faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
		faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
		faceapi.nets.faceExpressionNet.loadFromUri("/models"),
		faceapi.nets.ageGenderNet.loadFromUri("/models")
	]);
};

const EMOJIS: any = {
	male: "👨🏻‍💻",
	female: "👩🏻‍💻",
	neutral: "😐",
	happy: "😁",
	sad: "😔",
	angry: "😡",
	fearful: "😱",
	disgusted: "🤢",
	surprised: "😳"
};

const getMax = (probs: any): string => {
	let maxProb = 0;
	let prob = "";
	for (let key in probs) {
		if (probs[key] > maxProb) {
			prob = key;
			maxProb = probs[key];
		}
	}
	return prob;
};

export const detectAndDrawResults = (canvas: any, video: any) => {
	const dims = faceapi.matchDimensions(canvas, video, true);
	setInterval(async () => {
		const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions().withAgeAndGender();
		const resizedDetections = faceapi.resizeResults(detections, dims);
		canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
		faceapi.draw.drawDetections(canvas, resizedDetections);
		faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);
		resizedDetections.forEach((result: any) => {
			const { age, gender, expressions } = result;
			let expression = getMax(expressions);
			new faceapi.draw.DrawTextField([`Gender ${EMOJIS[gender]} `, `${faceapi.utils.round(age, 0)} years`, `You look ${EMOJIS[expression]}`], result.detection.box.topRight).draw(canvas);
		});
	}, 100);
};

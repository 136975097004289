import * as ml5 from "ml5";

let objectDetectorPromise: any = null;

export const loadModels = () => {
	if (objectDetectorPromise == null) {
		objectDetectorPromise = ml5.objectDetector("cocossd");
		return objectDetectorPromise;
	}
	//already loaded
	return objectDetectorPromise;
};

export const detectObjects = async (source: any) => {
	if (objectDetectorPromise != null) {
		let objectDetector = await objectDetectorPromise;
		return await objectDetector.detect(source);
	}
	return null;
};

export const drawDetections = (detections: Array<any>, canvas: any) => {
	const ctx = canvas.getContext("2d");
	detections.forEach((detection: any) => {
		const prob = Math.round(detection.confidence * 100);
		const label = detection.label + "( " + prob + "% )";
		ctx.beginPath();
		ctx.font = "16px serif";
		const textWidth = ctx.measureText(label).width;
		ctx.fillStyle = "lime";
		ctx.rect(detection.x, detection.y, textWidth, 20);
		ctx.fill();
		ctx.lineWidth = "4";
		ctx.strokeStyle = "lime";
		ctx.rect(detection.x, detection.y, detection.width, detection.height);
		ctx.stroke();
		ctx.fillStyle = "black";
		ctx.fillText(label, detection.x + 2, detection.y + 15);
	});
};

import { Box, Button, HStack, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FiCircle, FiStopCircle } from "react-icons/fi";
import { useVideo } from "../../../hooks/useVideo";

export default function Terminal() {
	const [recording, setRecording] = useState(false);
	const [str, setStr] = useState<Array<string>>([]);
	const { error, video, start, stop } = useVideo();

	useEffect(() => {
		if (video) {
			video.play();
			video.addEventListener(
				"loadedmetadata",
				() => {
					capture();
				},
				false
			);
		}
	}, [video]);

	useEffect(() => {
		if (error) {
			setRecording(false);
		}
	}, [error]);

	const startRecord = () => {
		setRecording(true);
		start({ height: 900 });
	};

	const stopRecord = () => {
		setRecording(false);
		stop();
		setStr([]);
	};

	const grayToChar = (value: number) => {
		let chars = ".,-~:;=!*#$@";
		let idx = 11 - Math.round((value * 11) / 255);
		return chars.charAt(idx);
	};

	const capture = () => {
		if (!video || video.paused || video.ended) {
			return;
		}
		let canvas: any = document.createElement("canvas");
		canvas.width = video.videoWidth / 10;
		canvas.height = video.videoHeight / 10;
		const context = canvas.getContext("2d");
		context.drawImage(video, 0, 0, canvas.width, canvas.height);
		let imageData = context.getImageData(0, 0, canvas.width, canvas.height);
		let lines: Array<string> = [];
		let line = "";
		for (let i = 0; i < imageData.data.length; i += 4) {
			if (i !== 0 && i % (4 * canvas.width) === 0) {
				lines.push(line);
				line = "";
			}
			let avg = (imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]) / 3;
			line += grayToChar(avg);
		}
		context.putImageData(imageData, 0, 0);
		setStr(lines);
		setTimeout(() => {
			capture();
		}, 33);
	};

	return (
		<Stack>
			<HStack>
				<Button disabled={error} onClick={recording ? stopRecord : startRecord} variant="solid" colorScheme="red" alignSelf="start" leftIcon={!recording ? <FiCircle /> : <FiStopCircle />}>
					{!recording ? "Start" : "Stop"}
				</Button>
			</HStack>
			<Box p={8} color="white" h="90vh" bg="black">
				<Text>~/dev/fun-stuff: $</Text>
				{error && <Text color="red">[terminal-video] Error: this feature requires access to your camera, Please allow it and refresh your browser </Text>}
				<Text lineHeight="1" fontFamily="mono" whiteSpace="pre-line" fontSize="10px">
					{str.join("\n")}
				</Text>
			</Box>
		</Stack>
	);
}

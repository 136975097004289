import { HStack, Stack, Text } from "@chakra-ui/layout";
import { Box, Radio, RadioGroup } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import FileInput from "../../../components/common/FileInput";
import { useVideo } from "../../../hooks/useVideo";
import { detectObjects, drawDetections, loadModels } from "../lib/api";

export default function ObjectDetector() {
	const [loading, setLoading] = useState(false);
	const [source, setSource] = useState("IMAGE");
	const { error, stream, start, stop } = useVideo();
	const [imgSrc, setImgSrc] = useState<string>("");
	const videoRef = useRef(null);
	const imageRef = useRef(null);
	const canvasRef = useRef(null);

	useEffect(() => {
		setLoading(true);
		loadModels().then(() => {
			setLoading(false);
		});
	}, []);

	useEffect(() => {
		if (stream) {
			let myVideo = videoRef.current as any;
			myVideo.srcObject = stream;
		}
	}, [stream]);

	useEffect(() => {
		clearCanvas();
		if (source === "VIDEO") {
			setImgSrc("");
			start({});
			let video = videoRef.current as any;
			if (video) {
				video.addEventListener("loadedmetadata", async () => {
					setInterval(async () => {
						const canvas = canvasRef.current as any;
						canvas.width = video.videoWidth;
						canvas.height = video.videoHeight;
						const detections = await detectObjects(video);
						drawDetections(detections, canvas);
					}, 100);
				});
			}
		} else if (stream) {
			stop();
		}
	}, [source]);

	const clearCanvas = () => {
		const canvas = canvasRef.current as any;
		const context = canvas.getContext("2d");
		context.clearRect(0, 0, canvas.width, canvas.height);
	};

	const onFileChange = (input: any) => {
		clearCanvas();
		setImgSrc("");
		if (input.target.files.length > 0) {
			let reader = new FileReader();
			reader.readAsDataURL(input.target.files[0]);
			reader.onloadend = async e => {
				const img = imageRef.current as any;
				img.src = reader.result;
				setImgSrc(reader.result as string);
				const canvas = canvasRef.current as any;
				canvas.width = img.width;
				canvas.height = img.height;
				const detections = await detectObjects(img);
				drawDetections(detections, canvas);
			};
		}
	};

	return (
		<Stack>
			<RadioGroup value={source} onChange={setSource}>
				<HStack spacing={4}>
					<Text>Choose your input:</Text>
					<Radio disabled={loading} value="IMAGE">
						Single Image
					</Radio>
					<Radio disabled={loading} value="VIDEO">
						Video Stream
					</Radio>
				</HStack>
			</RadioGroup>
			{loading && <Text>Loading Model...</Text>}
			{error && <Text color="red">this feature requires access to your camera, Please allow it and refresh your browser </Text>}
			{source === "IMAGE" && (
				<>
					<FileInput onChange={onFileChange} />
				</>
			)}
			<Box h="90vh" position="relative">
				{source === "VIDEO" && <video ref={videoRef} autoPlay={true} muted />}
				{source === "IMAGE" && <img style={{ visibility: !imgSrc ? "hidden" : "visible" }} alt="to detect" ref={imageRef} />}
				<canvas style={{ position: "absolute", top: 0, left: 0 }} ref={canvasRef}></canvas>
			</Box>
		</Stack>
	);
}

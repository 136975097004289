import * as React from "react";
import { Text, ChakraProvider, theme, Heading } from "@chakra-ui/react";
import Sidebar from "./components/common/Sidebar";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Terminal from "./features/terminal/components/Terminal";
import FaceDetector from "./features/face-detector/components/FaceDetector";
import ObjectDetector from "./features/object-detector/components/ObjectDetector";

export const App = () => (
	<ChakraProvider theme={theme}>
		<Router>
			<Sidebar>
				<Switch>
					<Route path="/term">
						<Terminal />
					</Route>
					<Route path="/face">
						<FaceDetector />
					</Route>
					<Route path="/object">
						<ObjectDetector />
					</Route>
					<Route exact path="/">
						<Heading>Fun Stuff</Heading>
						<Text>This is a collection of some fun projects.</Text>
						<Text> 👈 &nbsp; Choose any project to preview.</Text>
					</Route>
				</Switch>
			</Sidebar>
		</Router>
	</ChakraProvider>
);
